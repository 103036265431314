body,
html {
  height: 100%;
}
.bg {
  background-image: url(main.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #8e9195;
}
.form-title {
  width: 100%;
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  vertical-align: center;
}
.formulario {
  width: 40%;
  max-width: 300px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
